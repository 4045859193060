<template>
  <v-sheet>
      <SettingsGeneral />
  </v-sheet>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import SettingsGeneral from '@/components/app/settings/settingsGeneral/SettingsGeneral.vue'
import SettingsServices from '@/components/app/settings/settingsServices/SettingsServices.vue'
import SettingsVeterinarians from '@/components/app/settings/settingsVeterinarians/SettingsVeterinarians.vue'

const tab = ref('general')
const tabs = [
  {
    label: 'General',
    value: 'general'
  },
  {
    label: 'Veterinarians',
    value: 'veterinarians'
  },
  {
    label: 'Services',
    value: 'services'
  }
]
</script>

<style scoped lang="scss">
</style>
