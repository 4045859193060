<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="icon icon-tabler icons-tabler-outline icon-tabler-mailbox"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10 21v-6.5a3.5 3.5 0 0 0 -7 0v6.5h18v-6a4 4 0 0 0 -4 -4h-10.5" />
    <path d="M12 11v-8h4l2 2l-2 2h-4" />
    <path d="M6 15h1" />
  </svg>
</template>
