<template>
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="icon icon-tabler icons-tabler-outline icon-tabler-clock-bolt">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M20.984 12.53a9 9 0 1 0 -7.552 8.355" />
        <path d="M12 7v5l3 3" />
        <path d="M19 16l-2 3h4l-2 3" />
    </svg>

</template>