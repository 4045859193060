export const timezone = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Asia/Kolkata',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay',
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg'
]

export const hours = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00'
]
export const visTimelineGroups = [
  { id: '9AM', content: '9 AM' },
  { id: '10AM', content: '10 AM' },
  { id: '11AM', content: '11 AM' },
  { id: '12PM', content: '12 PM' },
  { id: '1PM', content: '1 PM' },
  { id: '2PM', content: '2 PM' },
  { id: '3PM', content: '3 PM' },
  { id: '4PM', content: '4 PM' },
  { id: '5PM', content: '5 PM' },
  { id: '6PM', content: '6 PM' },
  { id: '7PM', content: '7 PM' },
  { id: '8PM', content: '8 PM' },
  { id: '9PM', content: '9 PM' },
  { id: '10PM', content: '10 PM' },
  { id: '11PM', content: '11 PM' },
  { id: '12AM', content: '12 AM' },
  { id: '1AM', content: '1 AM' },
  { id: '2AM', content: '2 AM' },
  { id: '3AM', content: '3 AM' },
  { id: '4AM', content: '4 AM' },
  { id: '5AM', content: '5 AM' },
  { id: '6AM', content: '6 AM' },
  { id: '7AM', content: '7 AM' },
  { id: '8AM', content: '8 AM' }
]

export const countryCallingCode = [
  '+376',
  '+971',
  '+93',
  '+1268',
  '+1264',
  '+355',
  '+374',
  '+244',
  '+672',
  '+54',
  '+1684',
  '+43',
  '+61',
  '+297',
  '+358',
  '+994',
  '+387',
  '+1246',
  '+880',
  '+32',
  '+226',
  '+359',
  '+973',
  '+257',
  '+229',
  '+590',
  '+1441',
  '+673',
  '+591',
  '+5997',
  '+55',
  '+1242',
  '+975',
  '+47',
  '+267',
  '+375',
  '+501',
  '+1',
  '+61',
  '+243',
  '+236',
  '+242',
  '+41',
  '+225',
  '+682',
  '+56',
  '+237',
  '+86',
  '+57',
  '+506',
  '+53',
  '+238',
  '+5999',
  '+61',
  '+357',
  '+420',
  '+49',
  '+253',
  '+45',
  '+1767',
  '+1809',
  '+213',
  '+593',
  '+372',
  '+20',
  '+212',
  '+291',
  '+34',
  '+251',
  '+358',
  '+679',
  '+500',
  '+691',
  '+298',
  '+33',
  '+241',
  '+44',
  '+1473',
  '+995',
  '+594',
  '+44',
  '+233',
  '+350',
  '+299',
  '+220',
  '+224',
  '+590',
  '+240',
  '+30',
  '+500',
  '+502',
  '+1671',
  '+245',
  '+592',
  '+852',
  '+61',
  '+504',
  '+385',
  '+509',
  '+36',
  '+62',
  '+353',
  '+972',
  '+44',
  '+91',
  '+246',
  '+964',
  '+98',
  '+354',
  '+39',
  '+44',
  '+1876',
  '+962',
  '+81',
  '+254',
  '+996',
  '+855',
  '+686',
  '+269',
  '+1869',
  '+850',
  '+82',
  '+965',
  '+1345',
  '+7',
  '+856',
  '+961',
  '+1758',
  '+423',
  '+94',
  '+231',
  '+266',
  '+370',
  '+352',
  '+371',
  '+218',
  '+212',
  '+377',
  '+373',
  '+382',
  '+590',
  '+261',
  '+692',
  '+389',
  '+223',
  '+95',
  '+976',
  '+853',
  '+1670',
  '+596',
  '+222',
  '+1664',
  '+356',
  '+230',
  '+960',
  '+265',
  '+52',
  '+60',
  '+258',
  '+264',
  '+687',
  '+227',
  '+672',
  '+234',
  '+505',
  '+31',
  '+47',
  '+977',
  '+674',
  '+683',
  '+64',
  '+968',
  '+507',
  '+51',
  '+689',
  '+675',
  '+63',
  '+92',
  '+48',
  '+508',
  '+64',
  '+1787',
  '+970',
  '+351',
  '+680',
  '+595',
  '+974',
  '+262',
  '+40',
  '+381',
  '+7',
  '+250',
  '+966',
  '+677',
  '+248',
  '+249',
  '+46',
  '+65',
  '+290',
  '+386',
  '+4779',
  '+421',
  '+232',
  '+378',
  '+221',
  '+252',
  '+597',
  '+211',
  '+239',
  '+503',
  '+1721',
  '+963',
  '+268',
  '+1649',
  '+235',
  '+262',
  '+228',
  '+66',
  '+992',
  '+690',
  '+670',
  '+993',
  '+216',
  '+676',
  '+90',
  '+1868',
  '+688',
  '+886',
  '+255',
  '+380',
  '+256',
  '+1',
  '+1',
  '+598',
  '+998',
  '+379',
  '+1784',
  '+58',
  '+1284',
  '+1340',
  '+84',
  '+678',
  '+681',
  '+685',
  '+377',
  '+967',
  '+262',
  '+27',
  '+260',
  '+263'
]

export const defaultAvailabilities = [
  {
    id: 1,
    dayOfWeek: 'mon',
    date: null,
    intervals: []
  },
  {
    id: 2,
    dayOfWeek: 'tue',
    date: null,
    intervals: []
  },
  {
    id: 3,
    dayOfWeek: 'wed',
    date: null,
    intervals: []
  },
  {
    id: 4,
    dayOfWeek: 'thu',
    date: null,
    intervals: []
  },
  {
    id: 5,
    dayOfWeek: 'fri',
    date: null,
    intervals: []
  },
  {
    id: 6,
    dayOfWeek: 'sat',
    date: null,
    intervals: []
  },
  {
    id: 7,
    dayOfWeek: 'sun',
    date: null,
    intervals: []
  }
]

export const serviceLabels = [
  { title: 'Exam' },
  { title: 'Medication' },
  { title: 'Procedure' },
  { title: 'Labwork' },
  { title: 'Surgery' },
  { title: 'Animal Specific' }
]
