<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-bar-to-left"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10 12l10 0" />
    <path d="M10 12l4 4" />
    <path d="M10 12l4 -4" />
    <path d="M4 4l0 16" />
  </svg>
</template>
